/* global TweenMax, Power2 */

var sliding = false;

function updateIndicator(slider, indicator) {
    $('.slide-indicator', slider).removeClass('active');
    $('.slide-indicator', slider).eq(indicator.index()).addClass('active');
    $('.current-slide', slider).html(indicator.index() + 1);
    // console.log(indicator.index() + 1);
}

function slideNext(slider) {
    // console.log('next');
    if (sliding) {
        return false;
    }

    var active;
    var next;
    sliding = true;

    active = $('.slide.active', slider);

    if (active.is(':last-child')) {
        next = $('.slide', slider).first();
    } else {
        next = active.next();
    }

    TweenMax.set(next, {
        x: slider.width()
    });

    TweenMax.to(active, slider.attr('data-speed'), {
        x: - slider.width(),
        // ease: Power0.easeNone
        ease: Power2.easeInOut
    });

    TweenMax.to(next, slider.attr('data-speed'), {
        x: 0,
        // ease: Power0.easeNone,
        ease: Power2.easeInOut,
        onStart: function () {
            updateIndicator(slider, next);
        },
        onComplete: function () {
            active.removeClass('active');
            next.addClass('active');
            updateIndicator(slider, next);
            sliding = false;
        }
    });
}

function slidePrevious(slider) {
    // console.log('previous');

    if (sliding) {
        return false;
    }

    var active;
    var prev;
    sliding = true;

    active = $('.slide.active', slider);

    if (active.is(':first-child')) {
        prev = $('.slide', slider).last();
    } else {
        prev = active.prev();
    }

    TweenMax.set(prev, {
        x: -slider.width()
    });

    TweenMax.to(active, slider.attr('data-speed'), {
        x: slider.width(),
        // ease: Power0.easeNone
        ease: Power2.easeInOut
    });

    TweenMax.to(prev, slider.attr('data-speed'), {
        x: 0,
        // ease: Power0.easeNone,
        ease: Power2.easeInOut,
        onStart: function() {
            updateIndicator(slider, prev);
        },
        onComplete: function() {
            active.removeClass('active');
            prev.addClass('active');
            updateIndicator(slider, prev);
            sliding = false;
        }
    });
}

function slideSet(slider, index) {
    var active = $('.slide.active', slider);
    var next = $('.slide', slider).eq(index);

    TweenMax.set(active, {
        x: -slider.width()
    });

    TweenMax.set(next, {
        x: 0
    });

    active.removeClass('active');
    next.addClass('active');
    updateIndicator(slider, next);
}

// setup the sliders

function setupSlider(slider) {
    TweenMax.set($('.slide', slider), { x: slider.width() });

    $('.slide', slider)
        .first()
        .addClass('active');
    $('.slide-indicator', slider)
        .first()
        .addClass('active');

    TweenMax.set($('.slide.active', slider), { x: 0 });
}

$('.slider').each(function () {
    var slider = $(this);
    setupSlider(slider);
});

//navigating the slider
$('body').on('click', '.slider .slide-navigation[data-direction="next"]', function () {
    var slider = $(this).parents('.slider');
    slideNext(slider);
    return false;
});

$('body').on('click', '.slider .slide-navigation[data-direction="previous"]', function () {
    var slider = $(this).parents('.slider');
    slidePrevious(slider);
    return false;
});

$('body').on('click', '.slider .slide-indicator', function () {
    var indicator = $(this);
    var slider = indicator.parents('.slider');

    if (indicator.hasClass('active')) {
        return false;
    }

    if (sliding) {
        return false;
    }

    var activeIndex = $('.slide.active', slider).index();
    var indicatorIndex = indicator.index();

    if (activeIndex > indicatorIndex) {
        let active = $('.slide.active', slider);
        let prev = $('.slide', slider).eq(indicatorIndex);
        sliding = true;

        TweenMax.set(prev, {
            x: -slider.width()
        });

        TweenMax.to(active, slider.attr('data-speed'), {
            x: slider.width(),
            // ease: Power0.easeNone
            ease: Power2.easeInOut
        });

        TweenMax.to(prev, slider.attr('data-speed'), {
            x: 0,
            // ease: Power0.easeNone,
            ease: Power2.easeInOut,
            onStart: function () {
                updateIndicator(slider, prev);
            },
            onComplete: function () {
                active.removeClass('active');
                prev.addClass('active');
                updateIndicator(slider, prev);
                sliding = false;
            }
        });

        return false;
    }

    if (indicatorIndex > activeIndex) {
        let active = $('.slide.active', slider);
        let next = $('.slide', slider).eq(indicatorIndex);
        sliding = true;

        TweenMax.set(next, {
            x: slider.width()
        });

        TweenMax.to(active, slider.attr('data-speed'), {
            x: - slider.width(),
            // ease: Power0.easeNone
            ease: Power2.easeInOut
        });

        TweenMax.to(next, slider.attr('data-speed'), {
            x: 0,
            // ease: Power0.easeNone,
            ease: Power2.easeInOut,
            onStart: function () {
                updateIndicator(slider, next);
            },
            onComplete: function () {
                active.removeClass('active');
                next.addClass('active');
                updateIndicator(slider, next);
                sliding = false;
            }
        });

        return false;
    }


    return false;
});