/* global ScrollMagic */

// pin the header 
if ($(window).width() > 970) {
    var homeController = new ScrollMagic.Controller();
    // fix the header
    new ScrollMagic.Scene({
        triggerHook: '0',
        // offset: ,
        triggerElement: '.header-navigation'
    })
        .setPin('.header-navigation')
        .setClassToggle('.project-stats-wrapper', 'pinned')
        // .addIndicators({name: "alumni-nav"})
        .addTo(homeController);
}

// open the sidebar
$('body').on('click', '.open-sidebar-menu', function() {
    if($(this).hasClass('opened')) {
        $('.open-sidebar-menu').removeClass('opened');
        $('.header-navigation-overlay').removeClass('opened');
        $('.header-navigation').removeClass('opened');
        return false;
    }

    $('.open-sidebar-menu').addClass('opened');
    $('.header-navigation-overlay').addClass('opened');
    $('.header-navigation').addClass('opened');
    return false;
});


// expand all classifieds
$('body').on('click', '.expand-all-classifieds', function() {
    if (!$('.classifieds .classified[data-mode="closed"]').length) {
        $('.classifieds .classified[data-mode="expanded"]').attr('data-mode', 'closed');
        return false;
    }

    $('.classifieds .classified[data-mode="closed"]').attr('data-mode', 'expanded');
    return false;
});

// clicking on the classified title
$('body').on('click', '.classified .expend-classified, .classified .category', function() {
    var $this = $(this);

    window.location.href = $this.attr('href');

    // var classified = $this.parents('.classified');
    // if(classified.attr('data-mode') == 'expanded') {
    //     window.location.href = $this.attr('href');
    //     return false;
    // }

    // classified.attr('data-mode', 'expanded');

    return false;
});

$('body').on('click', '.classified', function() {
    var classified = $(this);
    if(classified.attr('data-mode') == 'closed') {
        classified.attr('data-mode', 'expanded');
    }
    return false;
});


// show/hide email
$('body').on('click', '.contact-email-wrapper .show-hide', function() {
    var $this = $(this);
    var emailWrapper = $this.parents('.contact-email-wrapper');
    
    if(emailWrapper.attr('data-mode') == 'hide') {
        console.log('shiw');
        emailWrapper.attr('data-mode', 'show');
        return false;
    }

    emailWrapper.attr('data-mode', 'hide');
    return false;
});

// shere classified
$('body').on('click', '.share-classified', function() {
    openModal('.share-classified');
    return false;
});

$('body').on('click', '.print-classified', function() {
    window.print();
    // var classified = $('.classified-wrapper .classified')[0];

    // var newWindow = window.open('', 'Print-Window');
    // newWindow.document.open();
    // newWindow.document.write(`<html><body onload="window.print()">${classified.innerHTML}</body></html>`);
    // newWindow.document.close();

    // setTimeout(function() {
    //     newWindow.close();
    // }, 10);
    
    return false;
});