/* global Cleave */
jQuery.fn.selectText = function() {
    var doc = document;
    var element = this[0];
    var range, selection;
    // console.log(this, element);
    if (doc.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
    } else if (window.getSelection) {
        selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
    }
};

// modal functions
// include the close modal functions
function closeModal() {
    $('.overlay, .modal').removeClass('visible');
}

$('body').click(function(e){
    if($(e.target).attr('class') === 'overlay visible') {
        closeModal();
    }
});

$(document).keyup(function(e) {
    if (e.keyCode === 27) { // Esc
        closeModal();
    }
});

// $(document).keyup(function(e) {
//     if (e.keyCode ===13) { // Esc
//         if($('.modal.display-notice').hasClass('visible')) {
//             console.log('close modal');
//             $('.modal a.close-error').trigger('click');
//             // closeModal();
//             e.preventDefault();
//             return false;
//         }
//     }
// });


$('body').on('click', '.modal a.close', function() {
    closeModal();        
    return false;
});

$('body').on('click', '.ignore', function() {
    return false;
});
    

// add the filled class to the labels
$('body').on('focus', '.fields li input, .fields li textarea', function () {
    var $this = $(this);
    $this.keyup(function () {
        if ($this.val() !== '') {
            $this.parent('li').addClass('filled');
        } else {
            $this.parent('li').removeClass('filled');
        }
    });
});

$('body').on('keyup blur', '.input input', function () {
    var $this = $(this);
    if ($this.val() !== '') {
        $this.parent('.input').addClass('filled');
    } else {
        $this.parent('.input').removeClass('filled');
    }
});

$('body').on('blur', '.fields li input, .fields li textarea', function () {
    var $this = $(this);
    if ($this.val() !== '') {
        $this.parent('li').addClass('filled');
    } else {
        $this.parent('li').removeClass('filled');
    }
});


$('body').on('keyup blur', '.limit-wrapper input, .limit-wrapper textarea', function() {
    var $this = $(this);
    var limiter = $this.parents('.limit-wrapper');
    var input = $this;
    var limit = input.attr('maxlength');

    $('.limiter .current-length', limiter).html(input.val().length);
    $('.limiter .max-length', limiter).html(limit);
});

$('input[name*="phone"]').each(function() {
    var input = $(this);
    
    new Cleave(input[0], {
        phone: true,
        phoneRegionCode: 'US'
    });
});

$('input[name="expiry"]').each(function () {
    var input = $(this);

    new Cleave(input[0], {
        date: true,
        datePattern: ['m', 'y']
    });
});

$('input[name="month"]').each(function () {
    var input = $(this);

    new Cleave(input[0], {
        date: true,
        datePattern: ['m']
    });
});

$('input[name="year"]').each(function () {
    var input = $(this);

    new Cleave(input[0], {
        date: true,
        datePattern: ['y']
    });
});

$('input.date').each(function () {
    var input = $(this);

    new Cleave(input[0], {
        date: true,
        delimiter: '/',
        datePattern: ['m', 'd', 'Y']
    });
});

$('input.numeral').each(function () {
    var input = $(this);

    new Cleave(input[0], {
        numeral: true,
        prefix: input.attr('data-prefix') ? input.attr('data-prefix') : '',
        numeralThousandsGroupStyle: 'none'
    });
});
$('input.cleave').each(function () {
    var input = $(this);

    new Cleave(input[0], {
        prefix: input.attr('data-prefix') ? input.attr('data-prefix') : '',
        blocks: [input.attr('data-limit')],
        numericOnly: input.attr('data-numeric') ? true : false,
        uppercase: input.attr('data-uppercase') ? true : false
    });
});

$('input[name="card_number"]').each(function () {
    var input = $(this);

    new Cleave(input[0], {
        creditCard: true,
        onCreditCardTypeChanged: function (type) {
            input.parents('li').attr('data-type', type);

            var cvv = input.parents('ul').find('[name="cvv"]');
            cvv.attr('maxlength', type == 'amex' ? 4 : 3);
        }
    });
});

// function showNotice(header, message, button = 'Close') {
//     var scrollTop = $(window).scrollTop();
//     $('.display-notice').css({
//         'top': (scrollTop - 500) + 'px'
//     });

//     $('.display-notice h6').html(header);
//     $('.display-notice .notice-message').html(message);
//     $('.display-notice .button').html(button);
//     $('.overlay, .modal.display-notice').addClass('visible');
// }

function openModal(modal, offset = 500) {

    var scrollTop = $(window).scrollTop();
    $(modal).css({
        'top': (scrollTop - offset) + 'px'
    });

    setTimeout(function () {
        $(modal).addClass('visible');
        $(modal).find('.fields li input, .fields li textarea').each(function () {
            var $this = $(this);
            if ($this.val() !== '') {
                $this.parent('li').addClass('filled');
            } else {
                $this.parent('li').removeClass('filled');
            }
        });
    }, 100);

    $('.overlay').addClass('visible');
}


$('body').click(function (e) {
    if ($(e.target).attr('class') === 'overlay visible') {
        closeModal();
    }
});

$(document).keyup(function (e) {
    if (e.keyCode === 27) { // Esc
        closeModal();
    }
});

$('body').on('click', '.modal a.close', function () {
    closeModal();
    return false;
});

$('body').on('click', '.modal a.close-notice', function () {
    if ($('.modal.visible').length === 1) {
        $('.display-notice, .overlay').removeClass('visible');
    } else {
        $('.display-notice').removeClass('visible');
    }

    return false;
});

var animateDots;
function runDots() {
	var glowing = $('.notice-modal .dots .glowing').length;
    var total = $('.notice-modal .dots span').length;

    if(!glowing) {
    	$('.notice-modal .dots span').first().addClass('glowing');
    } else if (glowing === total) {
    	$('.notice-modal .dots span').first().removeClass('glowing');
    } else {
    	if($('.notice-modal .dots span:first').hasClass('glowing')) {
    		// add glowing to the next class
    		$('.notice-modal .dots .glowing').last().next().addClass('glowing');
    	} else {
    		$('.notice-modal .dots .glowing:first').removeClass('glowing');
    	}
    }

    animateDots = setTimeout(runDots,100);
}

function cancelDots() {
    clearTimeout(animateDots);
}

function showNotice(type, title, message = null, button = 'Close', href = null) {
    $('.notice-modal').removeClass('success, error');
    $('.dots span').removeClass('glowing');

    switch (type) {
        case 'processing':
            runDots();
            break;

        case 'success':
            $('.notice-modal').addClass('success');
            break;

        case 'error':
            $('.notice-modal').addClass('error');
            break;
    }

    $('.notice-modal h3').html(title);

    if (message) {
        $('.notice-modal p').show().html(message);
    } else {
        $('.notice-modal p').hide();
    }

    if (button) {
        $('.notice-modal .buttons').show();
        $('.notice-modal .button').html(button);

        if(href) {
            $('.notice-modal .button').attr('href', href);
            $('.notice-modal .button').removeClass('close');
        } else {
            $('.notice-modal .button').addClass('close');
            $('.notice-modal .button').attr('href', '#');
        }
    } else {
        $('.notice-modal .buttons').hide();
    }

    $('.notice-overlay, .notice-modal').addClass('visible');
}

function hideNotice() {
    cancelDots();
    $('.notice-overlay, .notice-modal').removeClass('visible');

    return false;
}

$('body').on('click', '.notice-modal a.close', function () {
    hideNotice();

    return false;
});

// function loading(text = 'Loading...') {
//     $('.loading-overlay, .loading').addClass('visible');
//     $('.loading span').html(text);
// }

// function unloading() {
//     $('.loading-overlay, .loading').removeClass('visible');
// }

// $('body').on('click', '.loading .hide-loading', function () {
//     unloading();
//     return false;
// });

// loading
// function loading(button) {
//     button.removeClass('loader no-loader');
//     button.addClass('active');
//     setTimeout(() => {
//         button.addClass('loader');
//     }, 200);
// }

// function unloading(button) {
//     button.removeClass('loader active');
//     button.addClass('no-loader');
//     setTimeout(() => {
//         button.removeClass('no-loader loader');
//     }, 200);
// }

$('.mask').each(function () {
    var $this = $(this);
    $this.mask($this.attr('data-mask'), {
        placeholder: $this.attr('data-placeholder')
    });
});


$('body').on('click', '.copy', function () {
    var $this = $(this);
    var element = $('<div>', {
        html: $this.siblings('.link').html()
    });
    $('body').append(element);

    // $this.siblings('.link').selectText();
    element.selectText();
    document.execCommand("Copy", false, null);
    window.getSelection().removeAllRanges();
    element.remove();

    $this.addClass('showing');
    setTimeout(() => {
        $this.removeClass('showing');
    }, 1500);
    return false;
});

$('body').on('click', '.open-dropdown', function() {
    var $this = $(this);
    var dropdown = $this.parents('.dropdown-wrapper');

    if(dropdown.hasClass('opened')) {
        closeDropdown();
        return false;
    }

    dropdown.addClass('opened');
    closeDropdown($this.parents('.dropdown-wrapper').attr('data-dropdown'));
    return false;
});

// disable the pinch to zoom on safari
document.documentElement.addEventListener('touchstart', function (event) {
  if (event.touches.length > 1) {
    event.preventDefault();
  }
}, false);

// disable the double tap to zoom
var lastTouchEnd = 0;
document.documentElement.addEventListener('touchend', function (event) {
  var now = (new Date()).getTime();
  if (now - lastTouchEnd <= 300) {
    event.preventDefault();
  }
  lastTouchEnd = now;
}, false);