$('body').on('click', '.open-image-viewer', function() {
    var $this = $(this);
    var slider = $this.parents('.slider');
    var classified = $this.parents('.classified');


    // update the cateogry and title
    $('.image-viewer .category').html($('.category', classified).html());
    $('.image-viewer .title').html($('.title', classified).html());

    // clear out the slides
    $('.image-viewer .slides').html('');
    $('.image-viewer .slider-indicators').html('');

    // add the slides to the viewer
    $('.slide', slider).each(function() {
        var slide = $(this);
        var img = $('img', slide).attr('src');

        $('.image-viewer .slides').append(
            `<div class="slide">
                <figure>
                    <img src="${img}" alt="${img}">
                </figure>
            </div>`
        );

        $('.image-viewer .slider-indicators').append('<a class="slide-indicator"></a>');
    });

    // setup the slider
    setupSlider($('.image-viewer .slider'));

    // slide to the correct index
    slideSet($('.image-viewer .slider'), $this.index());

    //  open the viewer
    $('.image-viewer').addClass('visible');
    return false;
});


$('body').on('click', '.image-viewer .close', function() {
    $('.image-viewer').removeClass('visible');
    return false;
});

$(document).keyup(function (e) {
    if (e.keyCode === 27) { // Esc
        $('.image-viewer').removeClass('visible');
    }

    if (e.keyCode === 37 && $('.image-viewer').hasClass('visible')) {
        $('.image-viewer .slide-navigation[data-direction="previous"]').trigger('click');
    }
    
    if (e.keyCode === 39 && $('.image-viewer').hasClass('visible')) {
        $('.image-viewer .slide-navigation[data-direction="next"]').trigger('click');
    }
});

// $('body').click(function(e) {
//     if ($(e.target).attr('class') === 'image-viewer visible') {
//         $('.image-viewer').removeClass('visible');
//     }
// });