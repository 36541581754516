// update the ad price
function updateNewClassifiedPrice() {
    var price = 0;

    // category
    if ($('.new-classified [name="category"]:checked').length) {
        var category = parseInt($('.new-classified [name="category"]:checked').attr('data-price'));
        category = isNaN(category) ? 0 : category;
        price += category;
    }

    // placement
    if ($('.new-classified [name="placement"]:checked').length) {
        var placement = parseInt($('.new-classified [name="placement"]:checked').attr('data-price'));
        placement = isNaN(placement) ? 0 : placement;
        price += placement;
    }

    // update the display price
    price = parseInt(price) ? '$' + price : 'Free';
    $('.new-classified .total-price').html(price);

    // update the form price
    // $('.new-classified [name="price"]').val($this.attr('data-price'));
}

$('body').on('change', '.new-classified [name="category"]', function () {
    // loop through the placements
    var $this = $(this);
    var placements = JSON.parse($this.attr('data-placements'));
    console.log(placements);

    $('.new-classified [name="placement"]').each(function() {
        var placement = $(this);
        placement.prop('disabled', false);
        
        if (placement.attr('data-disabled')) {
            placement.prop('disabled', true);
        }

        if(!placements.includes(placement.val())) {
            placement.prop('checked', false);
            placement.prop('disabled', true);
        }
    });

    updateNewClassifiedPrice();
    return false;
});

$('body').on('change', '.new-classified [name="placement"]', function() {
    updateNewClassifiedPrice();
    return false;
});


// copy the contact info to the ad info
$('body').on('change', '.new-classified input[name="copy"]', function() {
    var $this = $(this);
    if($this.prop('checked')) {
        // get all inputs with a name attr
        $('.new-classified .ad-information [data-name]:not([data-name=""])').each(function() {
            var input = $(this);

            if ($this.attr(`data-${input.attr('data-name')}`)) {
                input.val($this.attr(`data-${input.attr('data-name')}`))
            } else {
                // get the contact value from this input
                input.val($('.new-classified .customer-contact [data-name="'+input.attr('data-name')+'"]').val());
            }

            // if we have a value mark the parent as filled
            if(input.val()) {
                input.parents('li').addClass('filled');
            }
        });

        return false;
    }

    // get all inputs with a name attr and clear the input
    $('.new-classified .ad-information input[data-name]:not([data-name=""])').each(function() {
        $(this).val('');
    });

    return false;
});


// photos
$('body').on('click', '.new-classified .select-photos', function() {
    var uploader = $(this).parents('.add-photos');
    uploader.find('input[type="file"]').trigger('click');
    return false;
});

$('body').on('click', '.new-classified .delete-photo', function() {
    var $this = $(this);
    var photo = $this.parents('.photo');

    photo.remove();

    return false;
});

$('body').on('click', '.delete-classified-image', function() {
    var $this = $(this);
    var photo = $this.parents('.photo');

    $.ajax({
        type: 'POST',
        url: $this.attr('href'),
        data: {
            '_token': $('meta[name="csrf-token"]').attr('content'),
            '_method': 'DELETE'
        },
        dataType: 'json',
        success: function (data) {
            console.log(data);
            // unloading($('input[type="submit"]', $this));

            if (!data.success) {
                showNotice('error', 'Post Error', data.message, 'Try Again');
                return false;
            }

            hideNotice();
            photo.remove();
        },
        error: function (request) {
            console.log(request.responseText);
        }
    });
    
    return false;
});

function addPhotosFile(photos, file) {
    // create the photo element
    var photo = $('<div>', {
        class: 'photo',
        html: `<figure>
                <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt="${file.name}">
            </figure>
            <div class="upload-progress">
                <div class="current-progress"></div>
            </div>`
    });

    // add the photo to the photos
    $('.add-photos', photos).before(photo);

    // read the photo and put it as a preview
    var reader = new FileReader();
    reader.onload = function(e) {
        $('img', photo).attr('src', e.target.result);
    };
    reader.readAsDataURL(file);


    // start the upload process
    var payload = new FormData();
    payload.append('file', file);

    // add csrf for security
    payload.append('_token', $('meta[name="csrf-token"]').attr('content'));

    // start the upload process
    $.ajax({
		xhr: function() {
	        var xhr = new window.XMLHttpRequest();
	        xhr.upload.addEventListener('progress', function(e) {
	            if (e.lengthComputable) {
	                var percentComplete = e.loaded / e.total;
	               	percentComplete = Math.round(percentComplete * 100);

	               	photo.find('.current-progress').css({
	               		width: percentComplete+'%'
	               	});
                    
	               	if(percentComplete === 100) {
                        // uploaded 100%, but still waiting for a response
	               	}
	            }
	       }, false);
	       return xhr;
	    },
		type : 'POST',
		url : photos.attr('data-url'),
		data : payload,
		dataType : 'json',
		processData: false,
        contentType: false,
		success : function(data) {
			console.log(data);

			if(!data.success) {
                // Todo handle a photo with an error
				return false;
            }
    
            // replace the process with the delete photo
            $('.upload-progress', photo).replaceWith('<a href="" class="delete-photo icon-close"></a>');

            // TODO add a hidden input to connect the photo to the classified
            photo.append(`<input type="hidden" name="image[]" value="${data.file}">`);
            photo.append(`<input type="hidden" name="image_name[]" value="${data.file_name}">`);
		},
		error : function(request) {
            console.log(request.responseText);
            
            // !Request Failed, remove. This is just for testing
            $('.upload-progress', photo).replaceWith('<a href="" class="delete-photo icon-close"></a>');
		}
	});
}

$('body').on('change', '.new-classified .add-photos input[type="file"]', function() {
    var input = $(this);
    var photos = input.parents('.photos');
    var files = input[0].files;

    $.each(files, function(index, file) {
        addPhotosFile(photos, file);
    });
    return false;
});

if($('.new-classified .photos').length) {
    var photos = $('.new-classified .photos')[0];

    photos.ondragover = function(e) {
        e.preventDefault();
        $('.new-classified .photos').addClass('drag-over');
    };
    photos.ondragleave = function(e) {
        e.preventDefault();
        $('.new-classified .photos').removeClass('drag-over');
    };
    photos.ondrop = function(e) {
        e.preventDefault();
        $('.new-classified .photos').removeClass('drag-over');

        var files = e.dataTransfer.files;
        $.each(files, function(index, file) {
            addPhotosFile($('.new-classified .photos'), file);
        });
    };
}

// save the classified
$('body').on('click', '.save-new-classified', function() {
    var $this = $(this);
    $('.new-classified [name="mode"]').val($this.attr('data-mode'));
    $('.new-classified form').submit();
    return false;
});

// submit the new classified
$('body').on('submit', '.new-classified form', function () {
    var $this = $(this);
    var fields = $this.serializeArray();
    fields[fields.length] = { name: '_token', value: $('meta[name="csrf-token"]').attr('content') };

    var error = false;
    $('.new-classified').find('.required').each(function() {
        var input = $(this);

        if(input.attr('type') === 'radio') {
            if(!$('.new-classified').find('input[name="'+input.attr('name')+'"]:checked').length) {
                error = true;
            }
        }

        if(input.attr('type') === 'checkbox') {
            if(!input.prop('checked')) {
                error = true;
            }
        }

        if(input.val() === '') {
            error = true;
        }
    });

    if (error) {
        showNotice('error', 'Post Error', 'Please fill out all required fields', 'Try Again');
        return false;
    }

    // loading($('input[type="submit"]', $this));
    showNotice('processing', 'Processing', null, null);

    $.ajax({
        type: 'POST',
        url: $this.attr('action'),
        data: fields,
        dataType: 'json',
        success: function (data) {
            console.log(data);
            // unloading($('input[type="submit"]', $this));

            if (!data.success) {
                showNotice('error', 'Post Error', data.message, 'Try Again');
                return false;
            }

            if(data.location) {
                window.location.href = data.location;
                return false;
            }

            showNotice('success', 'Success', data.message, data.button ? data.button : 'Close', data.href ? data.href : null);
        },
        error: function (request) {
            console.log(request.responseText);
        }
    });

    return false;
});


// publish classified
$('body').on('change', '.publish-classified [name="card"]', function() {
    var $this = $(this);

    if ($this.val() == 'new') {
        $('.publish-classified .add-new-card').removeClass('hidden');
        $('.publish-classified .add-new-card input').each(function() {
            var input = $(this);
            input.prop('disabled', false);
            if(input.attr('data-required')) {
                input.addClass('required');
            }
        });
    } else {
        $('.publish-classified .add-new-card').addClass('hidden');
        $('.publish-classified .add-new-card input').removeClass('required');
        $('.publish-classified .add-new-card input').prop('disabled', true);
    }

    return false;
});

$('body').on('submit', '.publish-classified form', function () {
    var $this = $(this);
    var fields = $this.serializeArray();
    fields[fields.length] = { name: '_token', value: $('meta[name="csrf-token"]').attr('content') };

    var error = false;
    $('.publish-classified').find('.required').each(function () {
        var input = $(this);

        if (input.attr('type') === 'radio') {
            if (!$('.publish-classified').find('input[name="' + input.attr('name') + '"]:checked').length) {
                error = true;
            }
        }

        if (input.attr('type') === 'checkbox') {
            if (!input.prop('checked')) {
                error = true;
            }
        }

        if (input.val() === '') {
            error = true;
        }
    });

    if (error) {
        showNotice('error', 'Error', 'Please fill out all required fields', 'Try Again');
        return false;
    }

    // loading($('input[type="submit"]', $this));
    showNotice('processing', 'Processing', null, null);

    var payload = {};

    if ($('input[name="card"]').length && $('input[name="card"]:checked').val() != 'new') {
        payload['_token'] = $('meta[name="csrf-token"]').attr('content');
        payload.card = $('input[name="card"]:checked').val();

        $.ajax({
            type: 'POST',
            url: $this.attr('action'),
            data: payload,
            dataType: 'json',
            success: function (data) {
                console.log(data);
                if (!data.success) {
                    showNotice('error', 'Error', data.message, 'Try Again');
                    return false;
                }

                if (data.location) {
                    window.location.href = data.location;
                    return false;
                }

                showNotice('success', 'Success', data.message, data.button ? data.button : 'Close', data.href ? data.href : null);
            },
            error: function (request) {
                console.log(request.responseText);
            }
        });

        return false;
    }

    // setup the token
    Stripe.setPublishableKey($('meta[name="stripe"]').attr('content'));
    Stripe.card.createToken(
        {
            name: $('input[name="card_holder"]', $this).val(),
            number: $('input[name="card_number"]', $this).val(),
            cvc: $('input[name="cvv"]', $this).val(),
            exp: $('input[name="expiry"]', $this).val(),
            // exp_month: $('input[name="month"]', $this).val(),
            // exp_year: $('input[name="year"]', $this).val(),
            address_line1: $('input[name="street"]', $this).val(),
            address_line2: $('input[name="apt"]', $this).val(),
            address_city: $('input[name="city"]', $this).val(),
            address_state: $('input[name="state"]', $this).val(),
            address_zip: $('input[name="zip"]', $this).val()
        },
        function (status, response) {
            // console.log(response);
            if (response.error) {
                showNotice('error', 'Error', response.error.message, 'Close');
                return false;
            }

            payload['_token'] = $('meta[name="csrf-token"]').attr('content');
            payload.stripe_token = response.id;

            payload.card_holder = $('input[name="card_holder"]', $this).val();
            payload.street = $('input[name="street"]', $this).val();
            payload.apt = $('input[name="apt"]', $this).val();
            payload.city = $('input[name="city"]', $this).val();
            payload.state = $('input[name="state"]', $this).val();
            payload.zip = $('input[name="zip"]', $this).val();
            payload.brand = response.card.brand;
            payload.last_four = response.card.last4;
            payload.month = response.card.exp_month;
            payload.year = response.card.exp_year;

            $.ajax({
                type: 'POST',
                url: $this.attr('action'),
                data: payload,
                dataType: 'json',
                success: function (data) {
                    console.log(data);
                    if (!data.success) {
                        showNotice('error', 'Error', data.message, 'Try Again');
                        return false;
                    }

                    if (data.location) {
                        window.location.href = data.location;
                        return false;
                    }

                    showNotice('success', 'Success', data.message, data.button ? data.button : 'Close', data.href ? data.href : null);
                },
                error: function (request) {
                    console.log(request.responseText);
                }
            });

            return false;
        }
    );

    return false;
});