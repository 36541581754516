// submitting the register form
$('body').on('submit', '.register form', function () {
    var $this = $(this);
    var fields = $this.serializeArray();
    fields[fields.length] = { name: '_token', value: $('meta[name="csrf-token"]').attr('content') };

    var error = false;

    $('.register .required').each(function () {
        if ($(this).val() === '') {
            error = true;
        }
    });

    if (error) {
        showNotice('error', 'Registration Error', 'Please fill out all required fields', 'Try Again');
        return false;
    }

    // loading($('input[type="submit"]', $this));
    showNotice('processing', 'Processing', null, null);
    $.ajax({
        type: 'POST',
        url: $this.attr('action'),
        data: fields,
        dataType: 'json',
        success: function (data) {
            console.log(data);
            // unloading($('input[type="submit"]', $this));

            if (!data.success) {
                showNotice('error', 'Registration Error', data.message, 'Try Again');
                return false;
            }

            $('.register form')[0].reset();
            showNotice('success', 'Registration Success', data.message);
        },
        error: function (request) {
            console.log(request.responseText);
        }
    });

    return false;
});

// login form
$('body').on('submit', '.login form', function () {
    var $this = $(this);
    var fields = $this.serializeArray();
    fields[fields.length] = { name: '_token', value: $('meta[name="csrf-token"]').attr('content') };

    var error = false;

    $('.login .required').each(function () {
        if ($(this).val() === '') {
            error = true;
        }
    });

    if (error) {
        showNotice('error', 'Login Error', 'Please fill out all required fields', 'Try Again');
        return false;
    }

    // loading($('input[type="submit"]', $this));
    showNotice('processing', 'Processing', null, null);

    $.ajax({
        type: 'POST',
        url: $this.attr('action'),
        data: fields,
        dataType: 'json',
        success: function (data) {
            console.log(data);
            // unloading($('input[type="submit"]', $this));

            if (!data.success) {
                showNotice('error', 'Login Error', data.message, 'Try Again');
                return false;
            }

            // reload the page on success
            window.location.reload();
        },
        error: function (request) {
            console.log(request.responseText);
        }
    });

    return false;
});

// submitting the forgot-password form
$('body').on('submit', '.forgot-password form', function () {
    var $this = $(this);
    var fields = $this.serializeArray();
    fields[fields.length] = { name: '_token', value: $('meta[name="csrf-token"]').attr('content') };

    var error = false;

    $('.forgot-password .required').each(function () {
        if ($(this).val() === '') {
            error = true;
        }
    });

    if (error) {
        showNotice('error', 'Recover Password Error', 'Please fill out all required fields', 'Try Again');
        return false;
    }

    // loading($('input[type="submit"]', $this));
    showNotice('processing', 'Processing', null, null);
    $.ajax({
        type: 'POST',
        url: $this.attr('action'),
        data: fields,
        dataType: 'json',
        success: function (data) {
            console.log(data);
            // unloading($('input[type="submit"]', $this));

            if (!data.success) {
                showNotice('error', 'Recover Password Error', data.message, 'Try Again');
                return false;
            }

            $('.forgot-password form')[0].reset();
            closeModal();
            showNotice('success', 'Recover Password Success', data.message, 'Close');
        },
        error: function (request) {
            console.log(request.responseText);
        }
    });

    return false;
});

// submitting the reset-password form
$('body').on('submit', '.reset-password form', function () {
    var $this = $(this);
    var fields = $this.serializeArray();
    fields[fields.length] = { name: '_token', value: $('meta[name="csrf-token"]').attr('content') };

    var error = false;

    $('.reset-password .required').each(function () {
        if ($(this).val() === '') {
            error = true;
        }
    });

    if (error) {
        showNotice('error', 'Reset Password Error', 'Please fill out all required fields', 'Try Again');
        return false;
    }

    // loading($('input[type="submit"]', $this));
    showNotice('processing', 'Processing', null, null);
    $.ajax({
        type: 'POST',
        url: $this.attr('action'),
        data: fields,
        dataType: 'json',
        success: function (data) {
            console.log(data);
            // unloading($('input[type="submit"]', $this));

            if (!data.success) {
                showNotice('error', 'Reset Password Error', data.message, 'Try Again');
                return false;
            }

            // reload the page on success
            window.location.reload();
        },
        error: function (request) {
            console.log(request.responseText);
        }
    });

    return false;
});