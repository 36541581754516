/* globals Stripe */
$(function() {
	// @codekit-prepend 'js/functions.js'
    // @codekit-prepend 'js/dropdown.js'
    // @codekit-prepend 'js/slider.js'
    // @codekit-prepend 'js/tabs.js'
    // @codekit-prepend 'js/image-viewer.js'

    // @codekit-prepend 'js/accounts.js'

    // @codekit-prepend 'js/new-classified.js'
    // @codekit-prepend 'js/classifieds.js'
});